export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const USER_LOGGED_IN_DEFAULT_KEY = 'rtkn';
export const COOKIES_DEFAULT_PATH = '/';
export const GOOGLE_CLIENT_ID = '828754113184-5qeblfg7cpd06vrb9s795vse0uo398am.apps.googleusercontent.com';

export const WYNK_THUMBOR_BASE_URL = 'https://img.wynk.in/unsafe/';

export const XSTREAM_THUMBOR_BASE_URL = 'https://img.airtel.tv/unsafe/';

export const DEFAULT_IMAGE_URL = 'http://s3-ap-south-1.amazonaws.com/wynk-music-cms/music/default_img.png';

export const LIST_IMG_RATIO = '40x40';

export const ARTIST_LIST_IMG_RATIO = '96x96';

export const PREVIEW_IMG_RATIO = '140x140';

export const CARD_PREVIEW_IMG_RATIO = '300x300';

export const HOVER_PREVIEW_RATIO = '720x400';

export const USER_NOT_FOUND = 'No user found, contact admin (Priyanka Prajapati).';

export const GAMES_CATEGORIES = [
  'Strategy',
  'Arcade',
  'Simulation',
  'Sports',
  'Other',
  'Adventure',
  'Action',
  'Racing',
];

export const HP_CAROUSELS_TYPES = {
  // ADS: 'ads',
  IMAGE: 'image',
  VIDEO: 'video',
  GIF: 'gif',
};

export const HP_CAROUSELS_POSITIONS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const links = {
  HOMEPAGE: '/',
  ABOUT_US: '/about-us',
  CONTACT_US: '/help/support#contact',
  SUPPORT_PAGE: '/help/support',
  CONTENT_GUIDELINES: '/content-guidelines',
  PRIVACY_POLICY: '/help/privacy-policy',
  TERMS_OF_USE: '/help/terms-of-use',
  HOMEPAGE_CONTACT_FORM: '/#contact',
  PROFILE_SETUP: '/profile-setup',
  DASHBOARD: '/dashboard',
  LOGIN: '/login',
  MY_PROFILE: '/my-profile',
  TOURNAMENTS: '/tournaments',
};

export const DEEPLINK_TYPES = {
  PARAM_ID: 'id',
  USER_PROFILE: 'USER_PROFILE',
  TOURNAMENT_DETAIL: 'TOURNAMENT_DETAIL',
  GAME: 'GAME',
  PARAM_TYPE: 'type',
};

export const DEEPLINKS = {
  TOURNAMENT_DETAIL: 'TOURNAMENT_DETAIL',
  // GAME: "GAME", support required on Android, will enable after
  NONE: 'NONE',
};

export const SHARE_CONFIG = {
  [DEEPLINK_TYPES.TOURNAMENT_DETAIL]: {
    linkTitle: 'Tournament Link',
    route: links.TOURNAMENTS,
    mineText: `@{name} Join a new Tournament on Airtel Gaming.`,
    othersText: `@{name} Join a new Tournament on Airtel Gaming. Install the app now to participate.`,
  },
};
